import { useMemo } from 'react';

import FormHint from 'bloko/blocks/form/FormHint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Strings from 'Utils/Strings';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Currency } from 'src/models/currencies';
import { CurrencyType } from 'src/models/currencies.types';

const TrlKeys = {
    [CurrencyType.AZN]: 'currency.template.AZN',
    [CurrencyType.BYR]: 'currency.template.BYR',
    [CurrencyType.EUR]: 'currency.template.EUR',
    [CurrencyType.KZT]: 'currency.template.KZT',
    [CurrencyType.RUR]: 'currency.template.RUR',
    [CurrencyType.UAH]: 'currency.template.UAH',
    [CurrencyType.USD]: 'currency.template.USD',
    [CurrencyType.UZS]: 'currency.template.UZS',
    [CurrencyType.GEL]: 'currency.template.GEL',
    [CurrencyType.KGS]: 'currency.template.KGS',
};

interface SalaryInOtherCurrenciesProps {
    salary: number;
    currency: CurrencyType;
}

const SalaryInOtherCurrencies: TranslatedComponent<SalaryInOtherCurrenciesProps> = ({ trls, salary, currency }) => {
    const currencies = useSelector((state) => state.currencies.list.filter((currency) => currency.site === true));
    const currencyMap = useMemo(
        () =>
            currencies.reduce(
                (result, currency) => ({ ...result, [currency.code]: currency }),
                {} as Record<CurrencyType, Currency>
            ),
        [currencies]
    );

    const convert = (value: number, from: Currency, to: Currency): number => {
        return Math.round((value * to.rate) / from.rate / 10) * 10;
    };

    return (
        <FormHint>
            {currencies.map((option) => {
                if (option.code === currency || !currencyMap[currency]) {
                    return null;
                }
                const convertedSalary = convert(salary, currencyMap[currency], option);
                if (convertedSalary <= 0) {
                    return null;
                }
                return (
                    <div key={option.code}>
                        {format(trls[TrlKeys[option.code]], { '%d': Strings.formatCost(convertedSalary) })}
                    </div>
                );
            })}
        </FormHint>
    );
};

export default translation(SalaryInOtherCurrencies);
