import { useState } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import { FormItem, FormSpacer } from 'bloko/blocks/form';
import FormattedNumericInput from 'bloko/blocks/formattedNumericInput';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { THIN_SPACE } from 'src/utils/constants/symbols';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';
import CurrencySelect from 'src/components/AdvancedSearchFilters/CurrencySelect';
import InputLayoutWrapper from 'src/components/AdvancedSearchFilters/InputLayoutWrapper';
import SalaryInOtherCurrencies from 'src/components/AdvancedSearchFilters/SalaryInOtherCurrencies';

const TrlKeys = {
    fieldTitle: 'vacancy.searchForm.income',
    onlyWithSalary: 'vacancySearch.onlyWithSalary.true',
    notNumberError: 'require.only.numbers',
};

const Income: TranslatedComponent = ({ trls }) => {
    const initialCurrency = useSelector((state) => state.currencies.default);
    const onlyWithSalary = useSelector(
        ({ advancedVacancySearch }) => advancedVacancySearch.selectedValues[CriteriaKey.OnlyWithSalary]
    );
    const selectedValue = useSelector(
        ({ advancedVacancySearch }) => advancedVacancySearch.selectedValues[CriteriaKey.Salary]
    );

    const [currency, setCurrency] = useState(initialCurrency);
    const [value, setValue] = useState(selectedValue || '');
    const [showingValue, setShowingValue] = useState(selectedValue || '');
    const handleChange = (value: string) => {
        setValue(value.replace(THIN_SPACE, ''));
        setShowingValue(value);
    };

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <FormItem>
                <InputLayoutWrapper>
                    <input type="hidden" name={CriteriaKey.Salary} value={value} />
                    <FormattedNumericInput
                        value={showingValue}
                        onChange={handleChange}
                        size={10}
                        decimalLength={0}
                        errors={{
                            notNumber: trls[TrlKeys.notNumberError],
                        }}
                        data-qa={`advanced-search-${CriteriaKey.Salary}`}
                    />
                    <FormSpacer>
                        <CurrencySelect currency={currency} setCurrency={setCurrency} />
                    </FormSpacer>
                </InputLayoutWrapper>
                {currency && (
                    <>
                        <VSpacing base={1} />
                        {value !== '' && <SalaryInOtherCurrencies salary={Number(value)} currency={currency} />}
                    </>
                )}
            </FormItem>
            <FormItem>
                <Checkbox
                    name={CriteriaKey.OnlyWithSalary}
                    value="true"
                    defaultChecked={onlyWithSalary}
                    data-qa={`control-vacancysearch__only-with-compensation`}
                >
                    {trls[TrlKeys.onlyWithSalary]}
                </Checkbox>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(Income);
