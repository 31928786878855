import { useState } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import Column from 'bloko/blocks/column';
import FormItem from 'bloko/blocks/form/FormItem';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { UserType } from 'src/models/userType';
import { EM_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import DateSelector from 'src/components/AdvancedSearchFilters/DateSelector';

const TrlKeys = {
    checkboxDescription: 'vacancySearch.searchperiod.custom',
    dateFrom: 'dateFrom',
    dateTo: 'dateTo',
    changeDateAction: 'advanced.vacancy.search.change.date',
};

const BOSearchPeriod: TranslatedComponent = ({ trls }) => {
    const isBackOffice = useSelector(({ userType }) => UserType.BackOffice === userType);
    const { date_from: hasDateFrom, date_to: hasDateTo } = useSelector(({ router }) => router.location.query);

    const checkedInitialState = !!hasDateFrom || !!hasDateTo;
    const [checked, setChecked] = useState(checkedInitialState);
    if (!isBackOffice) {
        return null;
    }

    const toggleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <>
            <Column xs="4" s="8" m="3" l="4" />
            <Column xs="4" s="8" m="5" l="7">
                <Checkbox checked={checked} onChange={toggleHandler} data-qa="advancedSearch-BOSearchPeriod-toggle">
                    {trls[TrlKeys.checkboxDescription]}
                </Checkbox>

                {checked && (
                    <FormItem baseline>
                        <DateSelector course={CriteriaKey.DateFrom} />
                        {`${NON_BREAKING_SPACE}${EM_DASH}${NON_BREAKING_SPACE}`}
                        <DateSelector course={CriteriaKey.DateTo} />
                    </FormItem>
                )}
                <VSpacing base={7} />
            </Column>
        </>
    );
};

export default translation(BOSearchPeriod);
