import { useState } from 'react';

import Checkbox from 'bloko/blocks/checkbox';
import { FormHint, FormItem } from 'bloko/blocks/form';
import BlokoLink, { LinkAppearance, LinkKind } from 'bloko/blocks/link';
import { DataProviderItem } from 'bloko/blocks/suggest/types';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { SearchField } from 'src/models/vacancySearch/vacancySearchDictionaries';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';
import VacancySearchSuggest from 'src/components/AdvancedSearchFilters/VacancySearchSuggest';

const TrlKeys = {
    fieldTitle: 'vacancySearch.keywords',
    example: 'vacancySearch.textSearchExample.0',
    queryLangDescr: 'employer.resumesSearch.queryLangDescr',
    randomExampleText: {
        1: 'vacancySearch.textSearchExample.1',
        2: 'vacancySearch.textSearchExample.2',
        3: 'vacancySearch.textSearchExample.3',
        4: 'vacancySearch.textSearchExample.4',
        5: 'vacancySearch.textSearchExample.5',
    },
    searchByField: {
        title: 'vacancySearch.searchByFields',
    },
    searchFields: {
        [SearchField.Name]: 'vacancySearch.searchByFields.name',
        [SearchField.Description]: 'vacancySearch.searchByFields.description',
        [SearchField.CompanyName]: 'vacancySearch.searchByFields.company_name',
    },
};

const QASelector = 'control-vacancysearch__search_field-item';

const VacancySearchText: TranslatedComponent = ({ trls }) => {
    const { queryText, randomExampleNumber, searchFields } = useSelector(({ advancedVacancySearch }) => {
        return {
            queryText: advancedVacancySearch.selectedValues[CriteriaKey.Text],
            randomExampleNumber: advancedVacancySearch.randomExampleNumber,
            searchFields: advancedVacancySearch.selectedValues.searchFields,
        };
    });
    const searchFieldsDictionary = useSelector(
        ({ vacancySearchDictionaries }) => vacancySearchDictionaries.searchFields
    );
    const [suggestValue, setSuggestValue] = useState<DataProviderItem>({ text: queryText || '' });

    return (
        <AdvancedFilter legend={trls[TrlKeys.fieldTitle]}>
            <FormItem>
                <VacancySearchSuggest suggestValue={suggestValue} setSuggestValue={setSuggestValue} />
                <FormHint>
                    {`${trls[TrlKeys.example]} `}
                    <BlokoLink
                        kind={LinkKind.Secondary}
                        appearance={LinkAppearance.Pseudo}
                        onClick={() => {
                            setSuggestValue({ text: trls[TrlKeys.randomExampleText[randomExampleNumber]] });
                        }}
                    >
                        {trls[TrlKeys.randomExampleText[randomExampleNumber]]}
                    </BlokoLink>
                </FormHint>
            </FormItem>

            <VSpacing base={2} />

            <FormItem>{trls[TrlKeys.searchByField.title]}</FormItem>

            {Object.values(searchFieldsDictionary)?.map((field) => (
                <FormItem baseline key={field}>
                    <Checkbox
                        name="search_field"
                        value={field}
                        data-qa={`${QASelector} ${QASelector}_${field}`}
                        defaultChecked={searchFields?.includes(field)}
                    >
                        {trls[TrlKeys.searchFields[field]]}
                    </Checkbox>
                </FormItem>
            ))}
            <FormItem>
                <BlokoLink href={'/article/1175'} disableVisited>
                    {trls[TrlKeys.queryLangDescr]}
                </BlokoLink>
            </FormItem>
        </AdvancedFilter>
    );
};

export default translation(VacancySearchText);
