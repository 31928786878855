import { useEffect, useState, useMemo, PropsWithChildren, FC } from 'react';

import { FormItem } from 'bloko/blocks/form';
import InputText from 'bloko/blocks/inputText';
import Suggest, { SuggestLayer } from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import TagList from 'bloko/blocks/tagList';
import Tag from 'bloko/blocks/tagList/tag';

import { DistrictItem } from 'src/models/search/advancedSearch';
import fetcher from 'src/utils/fetcher';

import AdvancedFilter from 'src/components/AdvancedSearchFilters/AdvancedFilter';

declare global {
    interface FetcherGetApi {
        haveAreaDistricts: {
            queryParams: {
                area: string;
                filterMicro: boolean;
            };
            response: {
                result: boolean;
            };
        };
    }
}

interface DistrictProps {
    checkedValues: DistrictItem[];
    title: string;
    area: string;
    setCheckedValues: (v: DistrictItem[]) => void;
    autoInvite?: boolean;
}

const WILDCARD = '%QUERY%';
const REMOTE = `/autosuggest/multiprefix/v2?q=${WILDCARD}&d=districts_{area}`;

const District: FC<DistrictProps & PropsWithChildren> = ({
    title,
    checkedValues,
    area,
    setCheckedValues,
    autoInvite,
}) => {
    const [showDistricts, setShowDistricts] = useState(false);
    const [value, setValue] = useState('');
    useEffect(() => {
        fetcher
            .get('/shards/have_area_districts' as 'haveAreaDistricts', { params: { area, filterMicro: true } })
            .then((data) => {
                setShowDistricts(data.result);
            })
            .catch(() => {
                setShowDistricts(false);
            });
    }, [area]);

    const dataProvider = useMemo(() => createRemoteDataProvider(REMOTE.replace('{area}', area), WILDCARD), [area]);

    const handleSuggestChange = (item: DistrictItem) => {
        if (item) {
            const district = { ...item, id: `${item.id || ''}` };
            if (!checkedValues.map(({ id }) => id).includes(district.id)) {
                const newValues = [...checkedValues, district];
                setCheckedValues(newValues);
            }
            setValue('');
        }
    };

    const handleRemove = (id: string) => {
        setCheckedValues(checkedValues.filter((value) => value.id !== id));
    };

    if (!showDistricts) {
        return null;
    }

    return (
        <AdvancedFilter legend={title}>
            {checkedValues.length > 0 && (
                <FormItem>
                    <TagList
                        removable
                        items={checkedValues.map(({ id, text }) => (
                            <Tag key={id} onRemove={() => handleRemove(id || '')}>
                                <input type="hidden" name="district" value={id} />
                                {text}
                            </Tag>
                        ))}
                    />
                </FormItem>
            )}
            <FormItem>
                <Suggest
                    dataProvider={dataProvider}
                    itemContent={({ text }) => <div data-qa="address-edit-district-suggest-item">{text}</div>}
                    onChange={handleSuggestChange}
                    value={{ text: value }}
                    layer={autoInvite ? SuggestLayer.AboveOverlayContent : SuggestLayer.AboveContent}
                >
                    <InputText data-qa="searchform__district-input" value={value} onChange={setValue} />
                </Suggest>
            </FormItem>
        </AdvancedFilter>
    );
};

export default District;
